import React, { useState, useEffect, useRef } from "react"
import { loadStripe } from "@stripe/stripe-js"
import styled from "styled-components"
import "./academy.scss"
import qs from 'qs'
import axios from "axios"
import Header from "../components/academyPayments/Header"
const SectionWrapper = styled.div``
const SoldOut = styled.div`
display: grid;
justify-content: center;
margin-top: 8rem;
h1, h2 {
  color: white;
  text-align: center;
}`
const PageWrapper = styled.div``


//PRODUCTION STRIPE KEY
// pk_live_51I6eFjDU2Q2BWbcESpbp7ZpAguR6265gT5x5QMxM0NQRn4owSPvCpeyTFf8gpkVcEpGdVEFI7y2HobBtHIzoVCJ0004FbGGAg9

const ServiceAcademyForm = () => {
  //stripe promise
  const stripePromise = loadStripe(
    "pk_live_51I6eFjDU2Q2BWbcESpbp7ZpAguR6265gT5x5QMxM0NQRn4owSPvCpeyTFf8gpkVcEpGdVEFI7y2HobBtHIzoVCJ0004FbGGAg9"
    // "pk_test_51I6eFjDU2Q2BWbcEBnhsabSxBJfQWBsHHbDZpcXKyUMO0rtWVimNVgik8KqCpaIwBiuDW5SOMtYqWZRnMz6PLRYk00LAweTx90"
  )

  const [formData, setFormData] = useState({
    price: "$895.00",
  })
  const [showModal, setShowModal] = useState(false)
  const [showCompanyNameError, setShowCompanyNameError] = useState(true)
  const [showNameError, setShowNameError] = useState(true)
  const [showEmailError, setShowEmailError] = useState(true)
  const [showPhoneError, setShowPhoneError] = useState(true)
  const [showDateError, setShowDateError] = useState(true)
  const [showAttendeeError, setShowAttendeeError] = useState(true)
  const [showAttendeePhoneError, setShowAttendeePhoneError] = useState(true)
  const [showAtendeeEmailError, setShowAttendeeEmailError] = useState(true)
  const [formError, setFormError] = useState(false)
  const [classes, setClasses] = useState(false)
  const [currentClass, setCurrentClass] = useState('')
  const classIdRef = useRef()
  const classDesc = useRef()

  // localStorage.getItem("submittedData")

  const handlePaymentClick = async e => {
    e.preventDefault()
    if (window) {
      localStorage.setItem("submittedData", JSON.stringify(formData))
      console.log(formData)
    } else {
      return true
    }
    const stripe = await stripePromise
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          // Production Key
          price: "price_1JUa3yDU2Q2BWbcEPV9KaMaG",
          // Test KEY BELOW
          // price: "price_1JUa33DU2Q2BWbcE4xfID3m6",
          quantity: 1,
        },
      ],
      mode: "payment",
      // successUrl: "http://localhost:8000/payment-success/",
      // cancelUrl: "http://localhost:8000/payment-cancel/",
      successUrl: "https://www.factorycat.com/payment-success/",
      cancelUrl: "https://www.factorycat.com/payment-cancel/",
    })
  }

  const addTestData = () => {
    setFormData({
      company: "Should Only Be Getting One Email",
      email: "noerrorsplease@rpscorporation.com",
      phone: "222-222-2222",
      name: "Lauer",
      yourName: "Christopher",
      cellPhone: "262-555-5555",
      attendeeEmail: "chris.lauer@rpscorporation.com",
    })
  }

  const handleChange = e => {
    const value = e.target.value
    console.log(e.target.options)
    if(e.target.options !== undefined) {
      let selectedIndex = e.target.options.selectedIndex
      let selectedClass = e.target.options[selectedIndex].getAttribute('id')
      formData.classid = selectedClass
    } else {
      let selectedIndex = ''
      let selectedClass = ''
    }
    setFormData({
      ...formData,
      [e.target.name]: value,
      classID: formData.classid
    })
  }
  const closeModals = e => {
    e.preventDefault()
    setShowModal(false)
  }

  function SubmitFormData(e) {
    e.preventDefault()
    if(formData.company &&
      formData.yourName &&
      formData.email &&
      formData.phone &&
      formData.firstDate &&
      formData.name &&
      formData.cellPhone &&
      formData.attendeeEmail && 
      formData.classid &&
      !formError ) {
        setShowModal(true)
      }
      if(formData.company === undefined || formData.company === ""){
        setShowCompanyNameError("You need to enter a company name")
        setFormError(true)
      } else {
        setShowCompanyNameError(false)
        setFormError(false)
      } 
      if(formData.yourName === "" || formData.yourName === undefined) {
        setShowNameError("You need to enter your name")
        setFormError(true)
      } else {
        setFormError(false)
        setShowNameError(false)
      }
      if(formData.email === "" || formData.email === undefined) {
        setShowEmailError("You need to enter your email")
        setFormError(true)
      } else {
        setFormError(false)
        setShowEmailError(false)
      }
      if(formData.phone === "" || formData.phone === undefined) {
        setShowPhoneError("You need to enter your phone number")
        setFormError(true)
      } else {
        setFormError(false)
        setShowPhoneError(false)
      }
      if(formData.firstDate === "No Classes") {
        setShowDateError("No Classes are available")
        setFormError(true)
      } else if(formData.firstDate === "Select Date" || formData.firstDate === "" || formData.firstDate === undefined) {
        setShowDateError("You need to select a date")
        setFormError(true)
      } else {
        setFormError(false)
        setShowDateError(false)
      }
      if(formData.name === "" || formData.name === undefined) {
        setShowAttendeeError("You need to enter the atendees name")
        setFormError(true)
      } else {
        setFormError(false)
        setShowAttendeeError(false)
      }
      if(formData.cellPhone === "" || formData.cellPhone === undefined) {
        setShowAttendeePhoneError("You need to enter the attendees cell phone number")
        setFormError(true)
      } else {
        setFormError(false)
        setShowAttendeePhoneError(false)
      }
      if(formData.attendeeEmail === "" || formData.attendeeEmail === undefined) {
        setShowAttendeeEmailError("You need to enter the attendees email")
        setFormError(true)
      } else {
        setFormError(false)
        setShowAttendeeEmailError(false)
      } 
  }


  async function endPointPostClass() {
    let Dates = []
    await axios.post('https://www.factorycat.com/serviceSchoolFunctions.php', 'action=getClasses', {
          headers: {
        'content-type': 'application/x-www-form-urlencoded'
        }, 
      }).then((response) => {
        let Classes = response.data
        Classes.forEach(date => {
          Dates.push(date)
        })
        setClasses(Dates)
      }, (error) => {
        console.log("There was an error")
        // newClasses.forEach(date => {
        //   Dates.push(date)
        // })
        // setClasses(Dates)
      }) 
    }
    async function checkClassCapacity() {
      console.log(formData.classid)
      await axios.post('https://www.factorycat.com/serviceSchoolFunctions.php',  qs.stringify({action: `checkClass`, classID: formData.classid }), {
        headers: {
      'content-type': 'application/x-www-form-urlencoded', 
        }
    }).then((response) => {
      console.log(response)
      }, (error) => {
        console.log(error)
      })
    }
    useEffect(() => {
      endPointPostClass()
      return () => {
      }
    }, [])
  return (
    <PageWrapper className="page-wrapper">
      <Header />
     <div className={showModal ? "pop-up-wrapper showMe" : "pop-up-wrapper hideMe"}>
            <div className="pop-up">
            <h3>Review your submission:</h3>
            <p style={{ color: "red" }}></p>
            <p>Company: <input type="text" readOnly value={formData.company} /></p>
            <p>Your Name: <input type="text" readOnly value={formData.yourName} /></p>
            <p>Your Email: <input type="text" readOnly value={formData.email} /></p>
            <p>Your Phone: <input type="text" readOnly value={formData.phone} /></p>
            <p>Prefered Date: <input type="text" readOnly value={formData.firstDate} /></p>
            <p>Atendees Cell: <input type="text" readOnly value={formData.cellPhone} /></p>
            <p>Atendees Email: <input type="text" readOnly value={formData.attendeeEmail} /></p>
              <button role="link" onClick={handlePaymentClick} className="checkout">
                Checkout
              </button>
              <button  className="close" onClick={closeModals}>Go Back</button>
            </div></div>
            {/* Button to prefill form */}
          {classes ?  
          <SectionWrapper className="section-wrapper">
            <div>
            <h1>Service Academy Registration</h1>
            <p>
              This 3 day Service Academy is designed for all Service Techs for
              Basic, to in depth full line product knowledge.
            </p>
          {/* <button onClick={addTestData}>add test data</button> */}
            <p>Registration Costs: $895.00</p>
            {/* Button testing endpoint */}
            {/* <button onClick={endPointRequestClasses}>Get Request</button> */}

            {/* <button onClick={endPointPostClass}>Post Request</button> */}
            </div>
        {/* <h1> This page is under development, do not make any purchases. </h1> */}
          <form className="academy-form">
            <div className="input-wrapper">
            <label>Company Name</label>
            <input
              onChange={handleChange}
              name="company"
              value={formData.company}
              placeholder="Enter your company name"
              required
              autoComplete="off"
            />
            <p className="error">{showCompanyNameError}</p>

            </div>
            <div className="input-wrapper">
            <label>Your Name</label>
            <input
              onChange={handleChange}
              placeholder="Enter your name"
              name="yourName"
              value={formData.yourName}
              required
              autoComplete="off"
            />
            <p className="error">{showNameError}</p>
            </div>
            <div className="input-wrapper">
            <label>Your Email</label>
            <input
              type="email"
              placeholder="Enter your email address"
              onChange={handleChange}
              name="email"
              value={formData.email}
              required
              autoComplete="off"
            />
            <p className="error">{showEmailError}</p>
            </div>
            <div className="input-wrapper">
            <label>Your Phone Number</label>
            <input
              onChange={handleChange}
              name="phone"
              placeholder="Enter your phone number"
              value={formData.phone}
              required
              autoComplete="off"
            />
            <p className="error">{showPhoneError}</p>
            </div>
            <div className="input-wrapper">
            <label>Preferred Dates</label>
            <select
              onChange={handleChange}
              name="firstDate"
              value={formData.firstDate}
              required
            >
              <option selected disabled>
                Select Date
              </option>
              {classes.map((date) => (<option id={date.ClassID} name="ClassID">{date.Description}</option>))}
            </select>
            <p className="error">{showDateError}</p>
            </div>
            <div className="input-wrapper">
            <label>Attendee Name</label>
            <input
              onChange={handleChange}
              name="name"
              placeholder="Who will be attending?"
              value={formData.name}
              required
              autoComplete="off"
            />
            <p className="error">{showAttendeeError}</p>
            </div>
            <div className="input-wrapper">
            <label>Attendee Cell Phone</label>
            <input
              onChange={handleChange}
              name="cellPhone"
              value={formData.cellPhone}
              placeholder="Enter the attendees cell phone number"
              autoComplete="off"
            />
            <p className="error">{showAttendeePhoneError}</p>
            </div>
            <div className="input-wrapper">
            <label>Attendee Email Address</label>
            <input
              type="email"
              onChange={handleChange}
              name="attendeeEmail"
              placeholder="Enter the attendees email address"
              value={formData.attendeeEmail}
              autoComplete="off"
            />
            <p className="error">{showAtendeeEmailError}</p>
            </div>
            <button onClick={SubmitFormData}>Pay Now</button>
          </form>
            </SectionWrapper>: <SoldOut><h1>Service Academy Registration Sold Out.</h1>
            <h2>Please check back later</h2></SoldOut>}
    </PageWrapper>
  )
}

export default ServiceAcademyForm

 
